import { useRef, useState } from 'react';
import DictionaryContext from '../contexts/dictionaryContext';
import api from '../api';
import useMe from '../hooks/useMe';

const DictionaryProvider = ({ children }) => {
  const syncDictionaries = useRef({});
  const [dictionaries, setDictionaries] = useState({});
  const { v4url } = useMe();

  const getDictionary = async (resourceName, options={}, customName) => {
    const name = customName || resourceName;
    if(dictionaries[name]) return dictionaries[name];

    addToDictionary(name, []);
    const { data } = await api.get(`${v4url}/${resourceName}`, { params: options });
    addToDictionary(name, data);
    return data;
  };

  const addToDictionary = async (name, data) => {
    syncDictionaries.current[name] = data;
    setDictionaries({
      ...syncDictionaries.current,
      [name]: data
    });
  };

  function getNameById(resource, id) {
    if(!dictionaries[resource]) return '';
    
    return (dictionaries[resource].find(e => `${e.id}` === id) || { name: '' }).name;
  }

  return <DictionaryContext.Provider value={{ addToDictionary, getDictionary, dictionaries, getNameById }}>
    {children}
  </DictionaryContext.Provider>;
};

export default DictionaryProvider;