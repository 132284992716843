import propTypes from 'prop-types';

const Loader = ({ label }) => {
  const style = {
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.8)',
    right: 0,
    left: 0,
    top: 0,
    bottom: 0,
    zIndex: 500,
  };
  return (
    <div className="d-flex align-items-center justify-content-center" style={style}>
      <div className="d-flex flex-column align-items-center">
        <div className="spinner-border" />
        {label && <small className="text-muted mt-4">{label}</small>}
      </div>
    </div>
  );
};

Loader.propTypes = { label: propTypes.string };

Loader.defaultProps = { label: '' };

export default Loader;
