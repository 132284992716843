import { useCallback, useMemo, useRef, useState } from 'react';
import AppContext from '../contexts/appContext';
import { useNotification } from 'ap-utils';
import useMe from '../hooks/useMe';
import useSize from '../hooks/useSize';
import { useNavigate } from 'react-router-dom';


const AppProvider = ({ children }) => {
  const { notify } = useNotification();
  const nav = useNavigate();
  const { me } = useMe();
  const [showMobile, setShowMobile] = useState(true);
  const [stackingToasts, setStackingToasts] = useState([]);
  const [rightBottom, setRightBottom] = useState([]);
  const { size } = useSize();
  const [fixed, setFixed] = useState(false);
  const [modalFull, setModalFull] = useState();

  const toggleFullScreen = () => setModalFull(e => !e);

  // Browser Notify
  const browserNotify = useCallback((url, m) => {
    if (m.by && (m.by.id === me.id)) return;
    notify(`#${m.id} ${m.by.description}`, {
      body: m.title,
      icon: '/logo192.png'
    }, {
      onclick: () => {
        window.focus();
        nav(`${url}/${m.id}`);
      }
    });

  }, [me.id, nav, notify]);

  // TOAST
  const close = key => setStackingToasts(e => e.filter(t => t.key !== key));

  const showToast = useCallback(({ color = 'success', at = '', title = '', body = '', width = 300, delay = 1000 }) => {
    const key = Math.random().toString(16).slice(2);
    setStackingToasts(e => [
      ...e,
      { key, color, at, body, width, delay, title },
    ]);

    setTimeout(() => {
      close(key);
    }, delay);
  }, []);

  const toast = useMemo(() => {
    const t = {};
    const colors = ['primary', 'warning', 'info', 'success', 'secondary', 'danger', 'light'];
    colors.forEach(color => t[color] = (body, ...options) => {
      showToast({ color: color, body: body, ...options });
    });
    return t;
  }, [showToast]);

  // rightBottom

  const addRightBottom = useCallback((data) => {
    setRightBottom(list => {
      return list.find(e => e.name === data.name)
        ? list.map(e => e.name === data.name ? data : e)
        : [...list, data];
    });
  }, []);

  const deleteRightBottom = useCallback(name => {
    setRightBottom(list => list.filter(e => e.name !== name));
  }, []);

  // padding

  const paddingLeft = useMemo(() => {
    if (!me.id) return 0;
    if (me.isDriver && size < 1) return 0;
    if (fixed && size > 3) return 220;

    return 40;
  }, [fixed, me.id, me.isDriver, size]);

  return <AppContext.Provider value={{
    toast, browserNotify, showMobile, setShowMobile, addRightBottom,
    deleteRightBottom, paddingLeft, fixed, setFixed, modalFull, toggleFullScreen
  }}>
    {children}
    {
      stackingToasts.map((item, i) => (
        <div key={item.key} className={`toast fade toast-fixed toast-top-right bg-${item.color} show stacking-toast`} style={{ top: (item.title ? 100 : 50) * i + 10, right: 10 }}>
          {item.title && <div className="toast-header bg-success text-white">
            <strong className="me-auto">{item.title}</strong>
            <small>{item.at}</small>
            <button onClick={() => close(item.key)} className="ripple ripple-surface btn-close btn-close-white" type="button" role="button"></button>
          </div>}
          <div className={`toast-body bg-${item.color} text-white d-flex justify-content-between`}>
            <span>{item.body}</span>
            {!item.title && <button onClick={() => close(item.key)} className="ripple ripple-surface btn-close btn-close-white" type="button" role="button"></button>}
          </div>
        </div>
      ))
    }
    <div className="d-flex flex-column align-items-end" style={{ position: 'fixed', right: 30, bottom: (paddingLeft ? 30 : 60) }}>
      {rightBottom.sort((a, b) => a.index - b.index).map(({ name, element }) => <div key={name} className="mb-2">{element}</div>)}
    </div>
  </AppContext.Provider>;
};

export default AppProvider;
