import { createContext } from 'react';

const MeContext = createContext({
  me: {
    is: 'guest',
    globalRole: 'guest',
    isApManager: false,
    isDriver: false,
    isRegionalManager: false,
    isGlobalManager: false,
    isCustomer: false,
    isTyreManager: false,
    isAdmin: false,
    isServiceMan: false,
    isServiceAccountMan: false,
    isRo: false,
    isRenewal: false,
    isParking: false,
    isRemarketing: false,
    isAuctionUser: false,
    isLawyer: false,
    isDanone: false,
    isPepsi: false,
    isSalesDirector: false,
    isSalesManager: false,
    isExpenseApprover: false,
    isExpenseDirectorApprover: false,
    isExpenseFinanceApprover: false,
    isExpenseFinanceDirectorApprover: false,
    isExpenseCostControl: false,
    isExpenseAccountingApprover: false,
    isProjectManager: false,
    isTele2: false,
    isLogistic: false,
    isSales: false
  },
  setMe: () => { },
  tryMe: () => { },
  v4url: ''
});

export default MeContext;