import { ll } from './locale';

const whoIsUser = (data) => {
  if (!data.id) return {};

  const globalRoles = Object.entries(data)
    .filter(([k, v]) => {
      return k.includes('?') && v;
    })
    .map(([k]) => k.replace('?', ''));

  const is = globalRoles
    .map(e => ll('role_names', e))
    .filter(e => !['danone', 'pepsi', 'jti', 'tele2'].includes(e))
    .join(', ');

  const globalRole = (() => {
    if (data['erm?']) return 'erm';
    if (data['renewal_user?']) return 'service_user';
    if (data['driver?'] || data['fleet_manager?'] || data['global_manager?']) return 'customer';
    if (globalRoles.length === 1) return globalRoles[0];

    return 'guest';
  })();

  return {
    is: is,
    globalRole: globalRole,
    isApManager: data['erm?'],
    isDriver: data['driver?'],
    isRegionalManager: data['fleet_manager?'],
    isTyreManager: data['tyre_manager?'],
    canManageTyres: data['can_manage_tyres?'],
    isGlobalManager: data['global_manager?'],
    isCustomer: data['driver?'] || data['fleet_manager?'] || data['global_manager?'],
    isAdmin: data['admin?'],
    isServiceMan: data['service_user?'],
    isServiceAccountMan: data['service_account_manager?'],
    isRo: data.id === 567,
    isRenewal: data['renewal_user?'],
    isParking: data['parking?'],
    isRemarketing: data['remarketing?'],
    isAuctionUser: data['auction_user?'],
    isLawyer: data['lawyer?'],
    isDanone: data['danone?'],
    isPepsi: data['pepsi?'],
    isSalesDirector: data['sales_director?'],
    isSalesManager: data['sales_account_manager?'],
    isExpenseApprover: data['expense_approver?'],
    isExpenseDirectorApprover: data['expense_director_approver?'],
    isExpenseFinanceApprover: data['expense_finance_approver?'],
    isExpenseFinanceDirectorApprover: data['expense_finance_director_approver?'],
    isExpenseCostControl: data['expense_cost_control?'],
    isExpenseAccountingApprover: data['expense_accounting_approver?'],
    isProjectManager: data['project_manager?'],
    isTele2: data['tele2?'],
    isLogistic: data['logistic?'],
    isSales: data['sales_director?'] || data['sales_account_manager?'],
    isPowerAccountingUser: data['power_accounting_user?'],
  };
};

export default whoIsUser;
