import { isLocalhost } from "../../serviceWorker";


const dictionary = {};

const log = console.log;

const loadLocales = async (api) => {
  const { data } = await api.get('/v4/locales');
  Object.entries(data).forEach(([k, v]) => dictionary[k] = v);
};
const ll = (l1, l2) => {
  if (!l1 || l1 === '') {
    if (!isLocalhost) {
      log('Передано пустое значение в качестве ключа!');
    }
    return '-';
  }
  if (l1 && !l2) {
    if (dictionary['no_namespace'][l1]) return dictionary['no_namespace'][l1];
    if (!isLocalhost) {
      log(`%cСоздать переменную ${l1} без неймспейса!`, 'background-color: #4a0000; color: white;');
    }
    return l1;
  }
  if (l1 && l2) {
    if (dictionary[l1]) {
      if (dictionary[l1][l2]) return dictionary[l1][l2];
      if (!isLocalhost) {
        log(`%cСоздать переменную ${l2} в неймспейсе ${l1}!`, 'background-color: white; color: #4a0000');
      }
      return [l1, l2].join(':');
    }
    if (!isLocalhost) {
      log(`%cСоздать переменную ${l2} в новом неймспейсе ${l1}!`, 'color: orange');
    }
    return [l1, l2].join(':');
  }
  return '???';
};

export { dictionary as l, ll, loadLocales };