import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    this.setState({ error: { e: error.message, i: info } });
  }

  render() {
    const { hasError, error } = this.state;
    const { children } = this.props;
    const errorText = JSON.stringify(error);
    if (hasError) {
      if (errorText.includes('Loading chunk')) {
        return (
          <div>
            <h1>Код приложения устарел, обновите страницу!</h1>
            <span>
              {errorText}
            </span>
          </div>
        );
      }
      return (
        <div>
          <h1>Ошибка!</h1>
          <span>
            {errorText}
          </span>
        </div>
      );
    }

    return children;
  }
}

export default ErrorBoundary;
