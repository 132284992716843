import './stylesheets/application.scss';
import { useEffect, useState } from 'react';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import { BrowserRouter } from 'react-router-dom';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import Modal from 'react-modal';
import loadable from '@loadable/component';
import { registerPlugin, CopyPaste } from 'handsontable/plugins';

import ru from 'date-fns/locale/ru';
import { QueryClient, QueryClientProvider } from 'react-query';
import { MantineProvider } from '@mantine/core';
import DictionaryProvider from './utils/providers/dictionaryProvider';
import MeProvider from './utils/providers/meProvider';
import AppProvider from './utils/providers/appProvider';
import SizeProvider from './utils/providers/sizeProvider';
import { initialize } from './utils/api';
import ErrorBoundary from './components/errorBoundary';
import Loader from './components/loader';
import { IsTest, isLocalhost } from './serviceWorker';

const Body = loadable(() => import('./Body'));
registerLocale('ru', ru);
setDefaultLocale('ru');

Modal.setAppElement('#root');
registerPlugin(CopyPaste);

const App = () => {
  const [initializing, setInitializing] = useState(true);
  const queryClient = new QueryClient();

  useEffect(() => {
    initialize(() => setInitializing(false));
  }, [setInitializing]);

  useEffect(() => {
    if (IsTest) {
      document.title = `[TEST] ${document.title}`;
    } else if (isLocalhost) {
      document.title = `[DEV] ${document.title}`;
    }
  }, []);

  return initializing
    ? <Loader label="Подключаемся к серверу" />
    : (
      <ErrorBoundary>
        <BrowserRouter>
          <SizeProvider>
            <MeProvider>
              <MantineProvider>
                <AppProvider>
                  <DictionaryProvider>
                    <QueryClientProvider client={queryClient}>
                      <Body />
                    </QueryClientProvider>
                  </DictionaryProvider>
                </AppProvider>
              </MantineProvider>
            </MeProvider>
          </SizeProvider>
        </BrowserRouter>
      </ErrorBoundary>
    );
};

export default App;
