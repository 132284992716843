import { useLayoutEffect, useState } from 'react';
import SizeContext from '../contexts/sizeContext';


const SizeProvider = ({ children }) => {
  const [size, setSize] = useState(0);

  useLayoutEffect(() => {
    const updateSize = () => {
      let s = 0;
      if(window.innerWidth >= 576) s = 1;
      if(window.innerWidth >= 768) s = 2;
      if(window.innerWidth >= 992) s = 3;
      if(window.innerWidth >= 1200) s = 4;
      setSize(s);
    };
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return <SizeContext.Provider value={{ size }}>
    {children}
  </SizeContext.Provider>;
};

export default SizeProvider;