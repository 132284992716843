import axios from 'axios';
import { isLocalhost, IsTest } from '../serviceWorker';
import { useResource } from 'ap-utils';
import { loadLocales } from './locale';

const api = axios.create({
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  withCredentials: true
});
const testServerUrl = 'https://ap-test.ap-ru.com/';
let authurl = 'http://localhost:3005';
let wsurl = 'ws://localhost:3011/cable';
let outleturl = 'http://localhost:3000';
let websiteurl = 'http://localhost:3001';
// let salesurl = 'http://192.168.1.86:3000';
let salesurl = 'http://localhost:3007';
let ermurl;

const initialize = async (callback) => {
  authurl = await getApi();
  api.defaults.baseURL = authurl + '/api';
  useResource.configure({
    apiInstance: api,
    qParams: { arrayFormat: 'bracket' }
  });
  await loadLocales(api);
  callback();
};

const getApi = async () => {
  let path = 'https://auth.ap-ru.com/api/v4/ping';
  try {
    const local = axios.get('https://a.ap-ru.com/api/v4/ping');
    const external = axios.get('https://auth.ap-ru.com/api/v4/ping');
    const { config: { url } } = await Promise.race([local, external]);
    path = url;
  } catch (err) {
    console.log(err);
  }
  path = path.replace('/api/v4/ping', '');
  const external = path.includes('auth.ap-ru.com');
  ermurl = `https://${external ? 'erm' : 'e'}.ap-ru.com`;
  outleturl = 'https://carsoutlet.ru/';
  websiteurl = 'https://ap-ru.com/';
  salesurl = 'https://sales-block-test.ap-ru.com/';
  if (isLocalhost) {
    ermurl = 'http://localhost:9293';
    outleturl = 'http://localhost:3000';
    websiteurl = 'http://localhost:3001';
    // salesurl = 'http://192.168.1.86:3000';
    salesurl = 'http://localhost:3007';
    return 'http://localhost:3005';
  }
  if (IsTest) {
    ermurl = 'https://erm-test.ap-ru.com';
    outleturl = 'https://outlet-test.ap-ru.com/';
    websiteurl = 'https://site-test.ap-ru.com'
    salesurl = 'https://sales-block-test.ap-ru.com/';
    return 'https://auth-test.ap-ru.com';
  }
  wsurl = `wss://${external ? 'cp' : 'c'}.ap-ru.com/cable`;
  return path;
};

export default api;

export { wsurl, ermurl, authurl, initialize, outleturl, websiteurl, testServerUrl, salesurl };