import axios from 'axios';
import api, { salesurl } from './api';

import Qs from 'qs';

const salesApi = axios.create({
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
  withCredentials: true,
  paramsSerializer: params => {
    return Qs.stringify(params, {
      arrayFormat: "brackets",
      encode: false
    });
  }
});

const authorize = token => {
  salesApi.defaults.baseURL = `${salesurl}/api/v1`;
  salesApi.defaults.headers.common.Authorization = `Bearer ${token}`
}

const apiInstance = source => {
  if (source === 'sales') return salesApi;
  return api;
}

export default apiInstance;

export { salesApi, authorize }