import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useEffect, useMemo } from 'react';
import { useState } from 'react';
import MeContext from '../contexts/meContext';
import api from '../api';
import whoIsUser from '../whoIsUser';
import { authorize } from '../apiInstance';

const MeProvider = ({ children }) => {
  const nav = useNavigate();
  const { pathname } = useLocation();
  const [me, setMe] = useState({
    id: null,
    isRo: false,
    globalRole: 'external'
  });
  const [loading, setLoading] = useState(false);

  const can = useCallback(r => {
    if (!me.id) return false;

    return r.includes('all')
      || me.isRo
      || r.map(is => me[is]).some(Boolean);
  }, [me]);

  useEffect(() => {
    api.interceptors.response.use(
      response => response,
      error => {
        if (!error) location.reload();
        if (error?.response?.status === 401) {
          if (pathname.startsWith('/external') || pathname === '/login') return;
          nav('/login');
        }
        return Promise.reject(error?.response);
      });
  }, [nav, pathname]);

  useEffect(() => authorize(me.access_token_sales), [me.access_token_sales])

  const tryMe = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get('/v4/common/users/me');
      const user = data.logged_as ? { ...data.logged_as, parent: data } : data;
      setMe({
        ...user,
        ...whoIsUser(user)
      });
      setLoading(false);
      return true;
    } catch {
      setLoading(false);
      setMe({});
      return false;
    }
  }, []);

  const v4url = useMemo(() => `/v4/${me.globalRole || 'external'}`, [me.globalRole]);

  return <MeContext.Provider value={{ me, setMe, tryMe, loading, can, v4url }}>
    {children}
  </MeContext.Provider>;
};

export default MeProvider;